import { Skeleton, Flex, Spinner } from '@cycle-app/ui';
import { CloseIcon, InfoIconOutline, DuplicateIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DocPanelWithBoardConfig } from 'src/app/Main/Board/DocPanel/DocPanel';
import { ReleasesPageTitle } from 'src/components/PageTitle/PageTitle';
import { PageId, routing } from 'src/constants/routing.constant';
import { ReleaseNotesProvider } from 'src/contexts/releaseNotesContext';
import { useNavigate } from 'src/hooks';
import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useParams } from 'src/hooks/router/useParams';
import { usePageId } from 'src/hooks/usePageId';
import { useGetPermission } from 'src/reactives';
import { copyNodeContents } from 'src/utils/html.utils';
import { addToaster } from 'src/utils/toasters.utils';

import {
  Container, ReleaseNoteHeader, HeaderContent, Left, Right, Nav, Action,
  EditorContainer, EditorHeader, Alert, StyledDocPanelRealtime,
  HiddenEditorContainer,
} from './ReleaseNote.styles';
import { ReleaseNoteDocOpen } from './ReleaseNoteDocOpen';
import { ReleaseNoteEditor } from './ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from './ReleaseNoteEditorTitle';
import { ReleaseNotePrevNext } from './ReleaseNotePrevNext';
import { ReleaseNoteTags } from './ReleaseNoteTags';

const useCloseReleaseNote = () => {
  const { releaseId } = useParams();
  const { navigateToRelease } = useNavigate();
  return () => {
    if (!releaseId) return;
    navigateToRelease(releaseId);
  };
};

export const ReleaseNote = () => {
  const [isCopying, setIsCopying] = useState(false);
  const hiddenEditorContainerRef = useRef<HTMLDivElement>(null);
  const { canUpdateReleaseNote } = useGetPermission();
  const {
    releaseId, noteId,
  } = useParams();
  const {
    release, isReleaseLoading, isReadonly,
  } = useRelease(releaseId);
  const {
    releaseNote, isReleaseNoteLoading,
  } = useReleaseNote(noteId);
  const closeReleaseNote = useCloseReleaseNote();
  const showContent = usePageId(pageId => pageId !== PageId.ReleaseDoc);
  if (!noteId || !releaseId || !release) return null;

  const isLoading = isReleaseLoading || isReleaseNoteLoading;
  const releaseDate = toShortLocaleDateString(release.date);

  const copyContentButton = (
    <Action
      tooltip="Copy release note to clipboard"
      tooltipPlacement="bottom"
      onClick={() => setIsCopying(true)}
    >
      {isCopying ? <Spinner /> : <DuplicateIcon size={16} />}
    </Action>
  );

  return (
    <>
      {releaseNote?.title && (
        <ReleasesPageTitle
          releaseDate={releaseDate}
          releaseNoteTitle={releaseNote?.title}
        />
      )}
      <Container
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: {
            opacity: 1,
            transition: {
              duration: 0.1,
              delay: 0.1,
            },
          },
          hidden: {
            opacity: 0,
            transition: {
              duration: 0.1,
            },
          },
        }}
      >
        <EditorContainer>
          {showContent && (
            <>
              <EditorHeader>
                <ReleaseNoteAlert releaseNoteId={noteId} />
                <ReleaseNoteTags isReadonly={isReadonly} releaseNoteId={noteId} />
                <ReleaseNoteEditorTitle
                  releaseNoteId={noteId}
                  isReadOnly={isReadonly || !canUpdateReleaseNote}
                />
              </EditorHeader>

              <ReleaseNoteEditor
                releaseNoteId={noteId}
                isReadOnly={isReadonly || !canUpdateReleaseNote}
              />
            </>
          )}
        </EditorContainer>

        <ReleaseNoteHeader>
          {isLoading ? (
            <HeaderSkeleton />
          ) : (
            <HeaderContent>
              <Left>
                <Nav>
                  <Action onClick={closeReleaseNote}>
                    <CloseIcon />
                  </Action>
                  <ReleaseNotesProvider
                    isReadonly={isReadonly}
                    showBugAndImprovements={release.showBugAndImprovements}
                    publicStatus={release.publicStatus}
                    releaseId={releaseId}
                    isNoValue={null}
                  >
                    <ReleaseNotePrevNext />
                  </ReleaseNotesProvider>
                </Nav>
              </Left>
              {noteId && (
                <Flex $gap={12}>
                  <StyledDocPanelRealtime />
                  <ReleaseNoteDocOpen releaseId={releaseId} noteId={noteId} />
                  {copyContentButton}
                </Flex>
              )}
            </HeaderContent>
          )}
        </ReleaseNoteHeader>

        <Switch>
          <Route path={routing[PageId.ReleaseDoc]}>
            <DocPanelWithBoardConfig />
          </Route>
        </Switch>
      </Container>

      {isCopying && (
        <HiddenEditorContainer ref={hiddenEditorContainerRef}>
          <ReleaseNoteEditorTitle
            releaseNoteId={noteId}
            isReadOnly
          />
          <ReleaseNoteEditor
            releaseNoteId={noteId}
            isReadOnly
            onLoad={async (editor) => {
              // Create a new paragraph at the end
              // Images are not copied if they are the last element
              editor?.chain().focus('end').createParagraphNear().run();

              await copyNodeContents(hiddenEditorContainerRef.current);
              setIsCopying(false);
              addToaster({ title: 'Release note copied to clipboard' });
            }}
          />
        </HiddenEditorContainer>
      )}
    </>
  );
};

const ReleaseNoteAlert = ({ releaseNoteId }: { releaseNoteId: string }) => {
  const { releaseNote } = useReleaseNote(releaseNoteId);
  if (!releaseNote?.isOther) return null;
  return (
    <Alert>
      <p>
        <InfoIconOutline />
      </p>
      <p>
        {'This release note is in the '}
        <strong>Other improvements & bugs section</strong>
        , the description will not appear in the public change log, only the title.
      </p>
    </Alert>
  );
};

const HeaderSkeleton = () => {
  const closeReleaseNote = useCloseReleaseNote();
  return (
    <HeaderContent>
      <Left>
        <Action onClick={closeReleaseNote}>
          <CloseIcon />
        </Action>
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={60} />
      </Left>
      <Right>
        <Skeleton height={20} width={60} />
        <Skeleton height={20} width={60} />
      </Right>
    </HeaderContent>
  );
};
