import { typo, ButtonV2 as Button, boxShadowZ1 } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div<{ $disabled: boolean }>`
  border-radius: 12px;
  overflow: hidden;
  ${p => !p.$disabled && `
    border: 1px solid ${p.theme.colors.docPanel.border};
  `}
`;

export const Header = styled.div<{ $disabled: boolean }>`
  position: relative;
  padding: 10px 10px 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 48px;
  cursor: pointer;
  ${p => p.$disabled && `
    cursor: default;
    background: ${p.theme.isDark ? p.theme.colors.background.secondaryElevated : ColorTheme.Blue100};
  `}
`;

export const Title = styled.div`
  ${typo.body500}
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const Content = styled.div`
  border-top: 1px solid ${p => p.theme.colors.docPanel.border};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MainButton = styled(Button).attrs({ color: 'gray' })`
  border: none;
  height: 28px;
  ${boxShadowZ1}
  color: ${ColorTheme.White};
  --bg: ${ColorTheme.Cycle};
  background: var(--bg);
  :hover {
    background: ${ColorTheme.Blue600};
  }
  :active {
    background: ${ColorTheme.Blue700};
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

export const loadingBackgroundCss = css`
  &, :hover, :active {
    background:
      linear-gradient(0deg, rgba(35, 69, 240, 0.5), rgba(35, 69, 240, 0.5)),
      linear-gradient(90.4deg, #AE8FFF 0.38%, #00D2F3 63.15%, #4DED8D 120.18%);
  }
`;

export const LoadingButton = styled(MainButton)`
  cursor: default !important;
  ${loadingBackgroundCss}
  background-size: 200% 200%;
  animation: ${gradientAnimation} 1s ease infinite;
`;

export const NoQuoteButton = styled(MainButton)`
  cursor: default !important;
  &, :hover, :active {
    background: hsla(var(--cycle), 0.5);
  }
`;

export const RetryButton = styled.div`
  color: hsl(var(--cycle));
  filter: none;
  cursor: pointer;
`;

export const CounterButton = styled(Button)`
  ${p => p.theme.isDark && `
    color: ${ColorTheme.White};
    :hover {
      background: ${ColorTheme.Grey850};
    }
    :active {
      background: ${ColorTheme.Grey800};
    }
  `}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${typo.body500}
  font-size: 13px;
  svg {
    color: ${p => (p.theme.isDark ? ColorTheme.Grey600 : ColorTheme.Grey400)};
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ListItem = styled.div<{
  $active: boolean;
  $secondary?: boolean;
}>`
  min-height: 32px;
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  color: ${p => (p.$secondary ? p.theme.colors.text.disabled : p.theme.colors.text.primary)};
  :hover {
    background: ${p => p.theme.colors.background.secondaryElevated};
    color: ${p => (p.$secondary ? p.theme.colors.text.primary : p.theme.colors.text.primary)};
  }
  ${p => p.$active && `
    background: ${p.theme.colors.background.secondaryElevated};
    color: ${p.$secondary ? p.theme.colors.text.secondary : p.theme.colors.text.primary};
  `};
  .ProseMirror {
    padding: 0 !important;
  }
`;

export const PopoverContent = styled.div`
  padding-bottom: 8px;
`;

export const ButtonsList = styled.div`
  ${boxShadowZ1}
  outline: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey800 : '#0000000F')};
  border-radius: 8px;
  height: 28px;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px #00000033;
  > * + * {
    :before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
    }
  }
`;

export const ButtonItem = styled(Button).attrs({ color: 'gray' })`
  background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.White)};
  :hover {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey100)};
  }
  :active {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
  }
  ${typo.caption500}
  color: ${p => p.theme.colors.text.primary};
  border: 0;
  border-radius: 0;
  gap: 6px;
  outline: none;
`;
