import { StatusCategory } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { CaretIcon, AiIcon, CheckCircleAltIcon } from '@cycle-app/ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { CommentEditor } from 'src/components/Editor';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useExtractQuotesContext } from 'src/contexts/extractQuotesContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { openVerifyQuotes } from 'src/reactives/docRightPanel.reactive';

import { ChangeQuoteFeature } from './ChangeQuoteFeature';
import { EditQuoteDropdown } from './EditQuoteDropdown';
import { DiscardQuote, QuotePopover, RemoveQuote, VerifyQuote, EditQuote } from './QuotePopover';
import {
  Container, Header, Title, Content, MainButton, LoadingButton, NoQuoteButton, CounterButton,
  Section, SectionTitle, List, ListItem, RetryButton,
} from './QuotesSummary.styles';
import { VerifyQuoteModal } from './VerifyQuoteModal';

type QuotesSummaryProps = {
  docId: string;
  docContentElement?: HTMLElement | null;
};

export const QuotesSummary = ({
  docId, docContentElement,
}: QuotesSummaryProps) => {
  // Used to highlight the quote in the list when his popover is open
  const [activeItem, setActiveItem] = useState<string | null>(null);
  // Used to open the verify quote modal
  const [verifyQuoteId, setVerifyQuoteId] = useState<string | null>(null);
  // Used to open the remove quote modal
  const [removeQuoteId, setRemoveQuoteId] = useState<string | null>(null);
  // Used to open the update quote modal
  const [updateQuoteId, setUpdateQuoteId] = useState<string | null>(null);

  const [isExpanded, { toggleCallback: toggleExpanded }] = useOptimizedBooleanState(false);

  const {
    status, extractQuotes, quotes, insights,
  } = useExtractQuotesContext();

  const disabled = status !== 'verify' && status !== 'verified';

  const isDocCompleted = useDocPanelContext(ctx => ctx.doc?.status?.category === StatusCategory.Completed);

  const setIsSummaryVisible = useExtractQuotesContext(ctx => ctx.setIsSummaryVisible);
  const { ref: headerRef } = useInView({
    root: docContentElement,
    threshold: 0.5,
    initialInView: true,
    onChange: inView => setIsSummaryVisible(inView),
  });

  return (
    <Container $disabled={disabled}>
      <Header
        onClick={toggleExpanded}
        $disabled={disabled}
        ref={headerRef}
      >
        <Title>
          {!disabled && (
            <CaretIcon
              size={11}
              direction={isExpanded ? 'bottom' : 'right'}
            />
          )}
          Summary of quotes
        </Title>

        {status === 'extract' && !isDocCompleted && (
          <MainButton onClick={extractQuotes}>
            <AiIcon size={14} />
            Extract quotes
          </MainButton>
        )}

        {status === 'loading' && (
          <LoadingButton onClick={e => { e.stopPropagation(); }}>
            <AiIcon isAnimated ize={14} />
            Looking for quotes…
          </LoadingButton>
        )}

        {status === 'retry' && (
          <NoQuoteButton onClick={e => { e.stopPropagation(); }}>
            <AiIcon ize={14} />
            No quote to verify
            <RetryButton onClick={extractQuotes}>
              Retry
            </RetryButton>
          </NoQuoteButton>
        )}

        {status === 'verified' && (
          <Tooltip
            withPortal
            withWrapper={false}
            placement="top"
            content="Remove existing AI-generated quotes if you want to extract new ones"
          >
            <NoQuoteButton onClick={e => { e.stopPropagation(); }}>
              <AiIcon size={14} />
              {verifyQuotesLabel()}
            </NoQuoteButton>
          </Tooltip>
        )}

        {status === 'verify' && quotes.length > 0 && (
          <CounterButton
            variant="naked"
            onClick={e => {
              e.stopPropagation();
              openVerifyQuotes();
            }}
          >
            {verifyQuotesLabel(quotes.length)}
          </CounterButton>
        )}
      </Header>
      <AnimatePresence>
        {isExpanded && !disabled && (
          <motion.div
            initial={{
              opacity: 0,
              height: 0,
            }}
            animate={{
              opacity: 1,
              height: 'auto',
            }}
            exit={{
              opacity: 0,
              height: 0,
            }}
          >
            <Content>
              {quotes.length > 0 && (
                <Section>
                  <SectionTitle>
                    <AiIcon hasGradient size={14} />
                    To verify
                  </SectionTitle>
                  <List>
                    {quotes.map(quote => (
                      <QuotePopover
                        key={quote.id}
                        onMount={() => setActiveItem(quote.id)}
                        onHide={() => setActiveItem(null)}
                        buttons={(
                          <>
                            <VerifyQuote onClick={() => setVerifyQuoteId(quote.id)} />
                            <DiscardQuote docId={docId} quoteId={quote.id} />
                          </>
                        )}
                      >
                        <ListItem $active={activeItem === quote.id}>
                          {quote.content && (
                            <CommentEditor
                              isReadOnly
                              content={quote.content}
                            />
                          )}
                        </ListItem>
                      </QuotePopover>
                    ))}
                  </List>
                </Section>
              )}

              {insights.length > 0 && (
                <Section>
                  <SectionTitle>
                    <CheckCircleAltIcon />
                    Verified
                  </SectionTitle>
                  <List>
                    {insights.map((insight) => (
                      <QuotePopover
                        key={insight.id}
                        onMount={() => setActiveItem(insight.id)}
                        onHide={() => setActiveItem(null)}
                        disabled={!insight.doc?.id}
                        buttons={(
                          <>
                            <EditQuote
                              onClick={() => {
                                if (!insight.doc?.id) return;
                                setUpdateQuoteId(insight.doc.id);
                              }}
                            />

                            <ChangeQuoteFeature
                              insightId={insight.id}
                            />

                            <RemoveQuote
                              onClick={() => {
                                if (!insight.doc?.id) return;
                                setRemoveQuoteId(insight.doc.id);
                              }}
                            />
                          </>
                        )}
                      >
                        <EditQuoteDropdown
                          insight={insight}
                          visible={updateQuoteId === insight.doc?.id}
                          hide={() => setUpdateQuoteId(null)}
                        >
                          <ListItem
                            key={insight.id}
                            $active={activeItem === insight.id || updateQuoteId === insight.doc?.id}
                          >
                            {insight.content && (
                              <CommentEditor
                                isReadOnly
                                content={insight.content}
                              />
                            )}
                          </ListItem>
                        </EditQuoteDropdown>
                      </QuotePopover>
                    ))}
                  </List>
                </Section>
              )}
            </Content>
          </motion.div>
        )}
      </AnimatePresence>

      {verifyQuoteId && (
        <VerifyQuoteModal
          docId={docId}
          quoteId={verifyQuoteId}
          hide={() => setVerifyQuoteId(null)}
        />
      )}

      {removeQuoteId && (
        <DocDeleteWarningModal
          docId={removeQuoteId}
          onHide={() => setRemoveQuoteId(null)}
        />
      )}
    </Container>
  );
};

export const verifyQuotesLabel = (count = 0) => {
  if (count === 0) return 'No quote to verify';
  if (count === 1) return '1 quote to verify';
  return `${count} quotes to verify`;
};
